<template>
  <transition name="fade">
    <router-view />
  </transition>
</template>

<script>
export default {
  name: 'Bet',
  created () {
    console.log(1111)
  }
}
</script>
